'use strict'

const slideWrapper = document.querySelector('[data-slide="wrapper"]') 
const slideList = document.querySelector('[data-slide="list"]') 
const navPreviousButton = document.querySelector('[data-slide="nav-previous-button"]')
const navNextButton = document.querySelector('[data-slide="nav-next-button"]')
const controlsWrapper = document.querySelector('[data-slide="controls-wrapper"]')
const slideItems = document.querySelectorAll('[data-slide="item"]')
let controlButtons

const state = {
    startingPoint : 0,
    savedPosition :0,
    currentPoint : 0,
    movement: 0,
    currentSlideIndex : 0,
}

function translateSlide(position){
    slideList.style.transform = `translateX(${position}px)`   
    state.savedPosition = position 
}

function getCenterPosition({ index }) {
    const slideItem = slideItems [index]
    const slideWidth = slideItem.clientWidth
    const windowWidth = document.body.clientWidth//si quieres revertir el centreado elimina esto
    const margin = (windowWidth - slideWidth) / 2// y esto (o vuelve a las 2 horas 20 min) 
    const position = margin - (index * slideWidth)
    return position
}
    
function setVisibleSlide({ index }){
    const position = getCenterPosition({index: index}) 
    state.currentSlideIndex = index
    slideList.style.transition = 'transform .5s'
    activeControlButton({ index })
    translateSlide(position) // y aqui ponle un - antes del position
}

function nextSlide(){
    setVisibleSlide({index: state.currentSlideIndex + 1})
}

function previousSlide(){
    setVisibleSlide({index: state.currentSlideIndex - 1})

    //slide-control-button fa-solid fa-circle
}

function createControlButtons() {
    slideItems.forEach(function(){
    
    const controlButton = document.createElement('button')
    controlButton.classList.add('slide-control-button')
    controlButton.classList.add('fas')
    controlButton.classList.add('fa-circle')
    controlButton.dataset.slide = 'control-button'
    controlsWrapper.append(controlButton)
    })
}
    
function activeControlButton({index}){
    const controlButton = controlButtons[index]
    controlButtons.forEach(function(controlButtonItem){
        controlButtonItem.classList.remove('active')
    })
    controlButton.classList.add('active')
}

function onMouseDown(event, index){
    const slideItem = event.currentTarget //o currentTarget faz referencia ao elemento que está sendo clicado
    state.startingPoint = event.clientX;
    state.currentPoint = event.clientX - state.savedPosition;
    state.currentSlideIndex = index
    slideList.style.transition = 'none'
    slideItem.addEventListener('mousemove', onMouseMove)

}
function onMouseMove(event){
    state.movement = event.clientX - state.startingPoint;
    const position = event.clientX - state.currentPoint;
    translateSlide(position)
}

function onMouseUp(event){
    const slideItem = event.currentTarget;
    const slideWidth = slideItem.clientWidth;
    console.log('mouseWidth', slideWidth)

    if(state.movement < -150){
        nextSlide()
    }
    else if (state.movement > 150){
        previousSlide()
    }
    else{
        setVisibleSlide({index: state.currentSlideIndex})
    }

    slideItem.removeEventListener('mousemove', onMouseMove);
   // console.log('soltei o botão do mouse')

}

function onControlButtonClick(index){
    setVisibleSlide({index})
}


function setListeners(){

    controlButtons = document.querySelectorAll('[data-slide="control-button"]')

    controlButtons.forEach(function(controlButton, index) {
         controlButton.addEventListener('click', function(event){
            onControlButtonClick(index)
         })
    })

    slideItems.forEach(function(slideItem, index){
        slideItem.addEventListener('dragstart', function(event){ //o event faz referencia ao evento que está acontecendo
            event.preventDefault();
        })
    
        slideItem.addEventListener('mousedown', function(event) {
            onMouseDown(event,index)
        })
        slideItem.addEventListener('mouseup', onMouseUp)
    })
    
    navNextButton.addEventListener('click', nextSlide)
    navPreviousButton.addEventListener('click', previousSlide)
}

function initSlider(){
    createControlButtons()
    setListeners()
    setVisibleSlide({index: 0})
}

initSlider()